import React from "react";
import Navside from "../App/Navside";
import Footer from "../components/Footer";
import Header from "../components/Header";
import { useContext } from "react";
import { LanguageContext } from "../App/UserContext";

function Jak() {
  const { language, changelanguage } = useContext(LanguageContext);
  return (
    <div className="grid">
      <header className="header">
      <Header Value={6} />
      </header>
      <main className="main">
        <h1>{language === 'en' ? 'How to get to us' : 'Jak se k nám dostat'}</h1>
        <p>{language === 'en' ? 'The apartment building has just been recently finished, so please do not be surprised if the descriptive number is not yet reflected on the online maps. But just enter "Dalma Grebaštica" in Google maps and you will smoothly reach us' : 'Budova apartmánu je právě dostavěná, proto nebuďte prosím překvapení,že číslo popisné ještě on-line mapy nereflektují. Stačí ale zadat do Google maps “Dalma Grebaštica” a dojedete hladce až k nám.'}</p>
<p>
          <iframe src="https://www.google.com/maps/embed?pb=!1m18!1m12!1m3!1d721.8890279282866!2d15.960875769663394!3d43.63659696879337!2m3!1f0!2f0!3f0!3m2!1i1024!2i768!4f13.1!3m3!1m2!1s0x13352393d66b9ac3%3A0x99f9b5323be2f4fc!2sDalma%20Greba%C5%A1tica!5e0!3m2!1scs!2scz!4v1684775582935!5m2!1scs!2scz"
          width="300"
          height="300"
          ></iframe>
          </p>
      </main>
      <footer className="footer">
        <Footer/>
      </footer>
    </div>
  );
}
export default Jak;
