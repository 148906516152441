import './App.css';
import Ceník from '../Ceník/Ceník'
import Onas from '../Onas/Onas'
import Rezervace from '../Rezervace/Rezervace'
import Galerie from '../Galerie/Galerie'
import Jak from '../Jak/Jak'
import Vyletyvokoli from '../Covokolí/Covokolí'
import Podminky from '../Podminky/Podminky'
import { Routes,Route } from 'react-router-dom';
import {LanguageContext} from './UserContext.js'
import { useState } from 'react';
function App() {
  const [language,setlanguage] = useState(LanguageContext);
  return (
<div>
  <LanguageContext.Provider value = {{language, setlanguage}}>
    <Routes> <Route  path="/" element={<Onas/>}/></Routes>
    <Routes> <Route  path="/Galerie" element={<Galerie/>}/></Routes>
    <Routes> <Route  path="/Rezervace" element={<Rezervace/>}/></Routes>
    <Routes> <Route  path="/Cenik" element={<Ceník/>}/></Routes>
    <Routes> <Route  path="/Jak" element={<Jak/>}/></Routes>
    <Routes> <Route  path="/Vyletyvokoli" element={<Vyletyvokoli/>}/></Routes>
    <Routes> <Route  path="/podminky" element={<Podminky/>}/></Routes>
  </LanguageContext.Provider>
</div>
  )
}
export default App;
