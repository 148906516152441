import React from "react";
import Navside from "../App/Navside";
import Footer from "../components/Footer";
import Header from "../components/Header";
import { useContext } from "react";
import { LanguageContext } from "../App/UserContext";

function Podminky() {
  const { language, changelanguage } = useContext(LanguageContext);
  return (
    <div className="grid">
      <header className="header">
      <Header Value={8} />
      </header>
      <main className="main">

       
          <h1>{language === 'en' ? 'General terms and conditions and cancellation conditions' : 'Všeobecné obchodní a storno podmínky'}</h1>

  <h3 className="levo" >I.{language === 'en' ? ' Definitions' : ' Definice'}</h3>
  <p id='obecne'>
<br/>    
{language === 'en' ? 'Client: The client is the person ordering the stay and all other persons who will be accommodated in the apartment. Provider: The provider is the owner of the apartment and at the same time the person with whom the client concluded a contractual relationship, the subject of which is accommodation in the apartment.' : 'Klient: Klientem je objednatel pobytu a dále všechny další osoby, které budou v apartmánu ubytované. Poskytovatel: Poskytovatelem je vlastník apartmánu a zároveň osoba se kterou klient uzavřel smluvní vztah jehož předmětem je ubytování v apartmánu.'}
</p>
<h3>II. {language === 'en' ? 'General conditions' : 'Obecná ustanovení'}</h3>
<p id='obecne'>
1. {language === 'en' ? 'The client’s accommodation in the apartment takes place on the basis of an accommodation contract concluded through the booking portal on the provider’s website, by email and by telephone under the conditions set out in these contractual and booking conditions.' : 'Ubytování klienta v apartmánu probíhá na základě smlouvy o ubytování uzavřené prostřednictvím rezervačního portálu na webových stránkách poskytovatele, emailem a telefonicky za podmínek stanovených v těchto smluvních a rezervačních podmínkách.'}
<br/> 
2.{language === 'en' ? 'The contractual and reservation conditions apply to the client’s temporary accommodation from the moment the stay reservation is confirmed. By booking a stay, the client agrees to these contractual and booking conditions.' : 'Smluvní a rezervační podmínky se vztahují na přechodné ubytování klienta od okamžiku potvrzení rezervace pobytu. Rezervací pobytu klient souhlasí s těmito smluvními a rezervačními podmínkami.'}
<br/> 
3. {language === 'en' ? 'The provider is entitled to accommodate only the client who has a binding reservation.' : 'Poskytovatel je oprávněn ubytovat pouze klienta, který má závaznou rezervaci.'}
<br/> 
4. {language === 'en' ? 'The client’s stay in the apartment is at his own risk. The provider is not responsible for injuries, damage or destruction of property and for damage caused by natural elements.' : 'Pobyt klienta v apartmánu je na jeho vlastní odpovědnost. Za úrazy, poškození nebo zničení majetku a za škody způsobené přírodními živly poskytovatel neodpovídá.'}
<br/> 
5. {language === 'en' ? 'By making a reservation, the client agrees to the processing of personal data for the purpose of providing accommodation and registering guests' : 'Rezervací klient souhlasí se zpracováním osobních údajů, a to za účelem poskytnutí ubytování a evidenci hostů'}
</p>
<h3 >III. {language === 'en' ? 'Subject of the contract' : 'Předmět smlouvy'}</h3>
<p id='obecne'>
1. {language === 'en' ? 'The subject of the contract is the provider’s obligation to provide the client with temporary accommodation in the Dalma Grebaštica apartment for the time and dates agreed in the booking form on the provider’s website or by email, and the client undertakes to pay the provider for the accommodation and services related to the accommodation.' : 'Předmětem smlouvy je závazek poskytovatele poskytnout klientovi přechodné ubytování v apartmánu Dalma Grebaštica na dobu a v termínech ujednaných v rezervačním formuláři na webových stránkách poskytovatel nebo emailem a klient se zavazuje poskytovateli zaplatit za ubytování a za služby spojené s ubytováním.'}
</p>
<h3>IV. {language === 'en' ? 'Price for accommodation' : 'Cena za ubytování'}</h3>
<p id='obecne'>
1. {language === 'en' ? 'The client undertakes to pay the provider the price for accommodation according to the price list, which can be found on the provider’s website.' : 'Klient se zavazuje zaplatit poskytovateli cenu za ubytování dle ceníku, který se nachází na webových stránkách poskytovatele.'}
<br/> 
2. {language === 'en' ? 'An accommodation order is created by filling out an electronic form for booking accommodation on the provider’s website or by email.' : 'Objednávka ubytování je vytvořena vyplněním elektronického formuláře pro rezervaci ubytování na webových stránkách poskytovatele nebo emailem.'}
<br/> 
3. {language === 'en' ? 'When the order is placed by the provider, accommodation in the apartment will be reserved. The reservation becomes binding for both the provider and the client when the deposit is received on the provider’s account.' : 'V okamžiku učinění objednávky ze strany poskytovatele dojde k rezervaci ubytování v apartmánu. Okamžikem přijetí zálohy na účet poskytovatele se rezervace stane závaznou pro poskytovatele i klienta.'}
<br/> 
4. {language === 'en' ? 'Method of payment for accommodation:' : 'Způsob úhrady ceny za ubytování:'}
<br/> 
a) {language === 'en' ? 'reservation deposit in the amount of 30% of the accommodation price, which is payable within 5 days of booking the accommodation on the provider’s website.' : 'rezervační záloha ve výši 30% z ceny ubytování, která je splatná do 5 dnů od rezervace ubytování na webových stránkách poskytovatele.'}
<br/> 
b) {language === 'en' ? 'the remaining part of the total price for accommodation after deducting the reservation deposit is to be paid no later than 21 days before the agreed arrival at the accommodation.' : 'zbylá část celkové ceny za ubytování po odečtení rezervační zálohy je splatná nejpozději do 21 dnů před sjednaným nástupem na ubytování.'}
<br/> 
5. {language === 'en' ? 'In case of cancellation of the reservation, or in a situation where the client does not board the accommodation, the procedure is according to Article V of these contractual and reservation conditions.' : 'V případě zrušení rezervace, případně v situaci, kdy klient nenastoupí na ubytování se postupuje dle článku V. těchto smluvních a rezervačních podmínek.'}
<br/> 
6. {language === 'en' ? 'In the event that the client makes a reservation less than 21 days before the requested start date of the accommodation, he is obliged to pay the full price for the accommodation within 24 hours after making the reservation and send a confirmation of payment to the provider by email.' : 'V případě, že klient učiní rezervací v době kratší než 21 dnů před požadovaným dnem počátku ubytování je povinen uhradit celou cenu za ubytování do 24 hodin po uskutečnění rezervace a zaslat emailem poskytovateli potvrzení o provedené platbě.'}</p>
<h3>V. {language === 'en' ? 'Cancellation of reservation and its consequences' : 'Zrušení rezervace a její následky'}</h3>
<p id='obecne'>
1. {language === 'en' ? 'The client is entitled to cancel a binding reservation under the conditions listed below.' : 'Klient je oprávněn zrušit závaznou rezervaci za níže uvedených podmínek.'}
<br/> 
2. {language === 'en' ? 'In case of cancellation of a binding reservation by the client, the following cancellation fees are charged to the client: - cancellation of the reservation by the client up to and including the 21st day before the booked start of the accommodation, the cancellation fee is 30% of the price of the accommodation - - cancellation of the reservation by the client from the 20th day inclusive before the booked at the beginning of accommodation, the cancellation fee is 100% of the accommodation price. - the client does not board the accommodation on the booked day of accommodation, the cancellation fee is 100% of the accommodation price.' : 'V případě zrušení závazné rezervace ze stran klienta jsou klientovi účtovány níže uvedené storno poplatky: - zrušení rezervace klientem do 21. dne včetně před zarezervovaným počátkem ubytování, činí storno poplatek 30% z ceny ubytování- - zrušení rezervace klientem od 20. dne včetně před zarezervovaným počátkem ubytování činí storno poplatek 100% z ceny ubytování. -klient nenastoupí na ubytování v zarezervovaný den ubytování činí storno poplatek 100% z ceny ubytování.'}
<br/> 
3. {language === 'en' ? 'If the client does not pay the reservation deposit of 30% or the remaining part of the accommodation price according to the conditions in Article IV. of these contractual and reservation conditions, the reservation will be canceled by the provider.' : 'Pokud klient neuhradí rezervační zálohu ve výši 30%, nebo zbylou část ceny ubytování dle podmínek v článku IV. těchto smluvních a rezervačních podmínek, bude rezervace poskytovatelem zrušena.'}
<br/> 
4. {language === 'en' ? 'If the client pays a reservation deposit of 30%, but does not pay the remaining part of the purchase price, according to the conditions specified in Article IV. of these contractual and reservation conditions, the stay will be canceled and the already paid deposit will be counted against the cancellation fee.' : 'Pokud klient uhradí rezervační zálohu 30%, ale neuhradí zbylou část kupní ceny, dle podmínek uvedených v článku IV. těchto smluvních a rezervačního podmínek, bude pobyt zrušen a již zaplacená záloha bude započtena na storno poplatek.'}
<br/> 
5. {language === 'en' ? 'Bank account number for payment in Czech crowns:' : 'Číslo účtu pro platbu v českých korunách:'}
<br/> 
1190019013/3030
<br/> 
{language === 'en' ? 'Bank account number for payment in euros:' : 'Číslo účtu pro platbu v eurech:'}
<br/> 
CZ45 3030 0000 0011 9001 9048
<br/> 
BIC AIRACZPP
<br/> 
Zuzana Tesařová
<br/> 
{language === 'en' ? 'Address: Křižíkova 447/74, 18600 Praha 8' : 'Adresa: Křižíkova 447/74, 18600 Praha 8'}
<br/> 
{language === 'en' ? 'Bank account number for payment in Czech crowns:' : 'Číslo účtu pro platbu v českých korunách:'}
<br/> 
{language === 'en' ? 'Bank account number: 1190019013/3030' : 'Číslo účtu: 1190019013/3030'}
</p>
<h3 >VI. {language === 'en' ? 'Start of stay and handover of the apartment' : 'Začátek pobytu a předání apartmánu'}</h3>
<p id='obecne'>
1. {language === 'en' ? 'The apartment is handed over to the client for use on the day of arrival from 3:00 p.m.' : 'Apartmán je klientovi předán k užívání v den příjezdu od 15.00 hod.'}
<br/> 
2. {language === 'en' ? 'The client collects the key to the apartment and to the entrance gate to the front garden, which the client is entitled to use based on the contract, from the smart keybox at the address of the accommodation' : 'Klíč od apartmánu i od vstupní branky na předzahrádku, které je na základě smlouvy klient oprávněn užívat, si klient vyzvedne ve smart keyboxu na adrese ubytování'}
<br/> 
3. {language === 'en' ? 'Upon first arrival to the apartment, the client is obliged to check that the apartment is free of defects and to check the completeness of the equipment. the inventory immediately reports detected defects to +420 731 610 080.' : 'Při prvním příchodu do apartmánu je klient povinen zkontrolovat, že je apartmán bez závad a zkontrolovat úplnost vybavení. inventární zjištěné závady neprodleně nahlásí na telefon +420 731 610 080.'}
</p>
<h3 >VII. {language === 'en' ? 'Stay' : 'Pobyt'}</h3>
<p id='obecne'>
1. {language === 'en' ? 'Accommodation is only possible for persons who show a valid identity document. The data from the identity document will be transferred by the accommodation provider to the database of the tourist center in Šibenik.' : 'Ubytovat je možné pouze osoby, které se prokáží platným dokladem totožnosti. Údaje z dokladu totožnosti předá ubytovatel do databáze turistického centra v Šibeniku.'}
<br/> 
2. {language === 'en' ? 'The operator reserves the right not to accommodate unannounced persons, including children.' : 'Provozovatel si vyhrazuje právo neubytovat předem nenahlášené osoby včetně dětí.'}
<br/> 
3. {language === 'en' ? 'Persons under the strong influence of alcohol, narcotic and psychotropic substances and persons with infectious diseases are prohibited from entering the building where the apartment is located.' : 'Do domu, kde je umístěn apartmán je zakázáno vstupovat osobám pod silným vlivem alkoholu, omamných a psychotropních látek a osobám s infekčními chorobami. Do budovy, kde je umístěn apartmán je zakázáno vnášet omamné a psychotropní látky.'}
<br/> 
4. {language === 'en' ? 'Smoking and fires are prohibited in the apartment.' : 'V apartmánu je zakázáno kouřit a rozdělávat oheň.'}
<br/> 
5. {language === 'en' ? 'Children under the age of 10 may only be accompanied by a person over the age of 18 in the apartment. It is not appropriate to leave children under the age of 10 unsupervised in the apartment.' : 'Děti mladší 10 let se v prostorách apartmánu smí pohybovat pouze v doprovodu osoby starší 18 let. Není vhodné ponechávat děti mladší 10-ti let bez dozoru v apartmánu.'}
<br/> 
6. {language === 'en' ? 'The apartment includes the right to use two parking spaces during your stay in the apartment. The client is obliged to use only this marked parking space. The parking lot is not guarded or secured in any way. The provider is not responsible for the vehicle or for things placed in the client’s vehicle. The client is obliged to secure the vehicle against theft or damage.' : 'K apartmánu náleží právo užívat, po dobu pobytu v apartmánu, dvě parkovací místa. Klient je povinen využívat pouze toto označené parkovací místo. Parkovací místo není hlídané ani nijak zabezpečené. Poskytovatel nenese odpovědnost za vozidlo ani za věci umístěné ve vozidle klienta. Klient je povinen zabezpečit vozidlo proti zcizení či poškození.'}
<br/> 
7. {language === 'en' ? 'Dogs and pets are not allowed.' : 'Pobyt psů a domácích zvířat není povolen.'}
<br/> 
8. {language === 'en' ? 'In the event of damage to the apartment, its equipment, or common areas by the client, the provider is entitled to demand appropriate financial compensation. The client also agrees that upon his departure the apartment and its equipment will correspond in terms of type and number of items to those upon his arrival. If this is not the case, he is obliged to compensate the provider for damages. In case of loss of keys, the client is obliged to cover the cost of acquiring and replacing key inserts.' : 'V případě poškození apartmánu, jeho vybavení, či společných prostor klientem, je poskytovatel oprávněn požadovat odpovídající finanční náhradu. Klient rovněž odpovídá, že bude při jeho odjezdu apartmán a jeho vybavení odpovídat co do druhu, a počtu položkám při jeho příjezdu. V případě, že tomu tak nebude, je povinen uhradit poskytovateli škodu. V případě ztráty klíčů je klient povinen uhradit náklady na pořízení a výměnu klíčových vložek.'}
<br/> 
9. {language === 'en' ? 'Quiet hours in the apartment building are from 10:00 p.m. to 8:00 a.m. The client is obliged to adhere to quiet hours.' : 'Noční klid v apartmánové budově je od 22:00 hod do 8:00 hod. Klient je povinen noční klid dodržovat.'}
<br/> 
10. {language === 'en' ? 'The client is not authorized to use his own electrical appliances in the apartment. This does not apply to electrical appliances used for personal hygiene of the client (shavers and massagers, hair dryers, etc.) and chargers for mobile phones, laptops, etc.' : 'Klient není oprávněný v apartmánu používat vlastní elektrické spotřebiče. Toto se netýká elektrických spotřebičů sloužících k osobní hygieně klienta (holící a masážní strojky, vysoušeče vlasů apod.) a nabíječek mobilních telefonů, notebooků apod.'}
<br/> 
11. {language === 'en' ? 'The client may not move the equipment of the apartment, make any modifications to the apartment or intervene in the electrical network or other installations without the consent of the provider.' : 'Klient nesmí bez souhlasu poskytovatele přemisťovat vybavení apartmánu, provádět jakékoli úpravy apartmánu či zásahy do elektrické sítě či jiných instalací.'}
<br/> 
12. {language === 'en' ? 'When leaving the apartment, the client is obliged to turn off all electrical appliances, turn off the lights and close the windows and doors. The client is obliged to lock the entrance door to the apartment as well as the entrance door to the front garden every time he leaves the apartment.' : 'Klient je povinen při ukončení pobytu z apartmánu vypnout veškeré elektrické spotřebiče, zhasnout světla a uzavřít okna a dveře. Klient je povinen při každém odchodu z apartmánu uzamknout vchodové dveře do apartmánu stejně jako vchodové dveře do předzahrádky.'}
<br/> 
13. {language === 'en' ? 'The client is obliged to secure his belongings so that they are not damaged, destroyed or stolen.' : 'Klient je povinen zabezpečit své věci tak, aby nedošlo k jejich poškození, zničení či odcizení.'}
<br/> 
14. {language === 'en' ? 'The client is obliged to immediately notify the provider or the administrator in case of the risk of fire, a situation requiring police intervention or medical treatment, the Client is obliged to do everything to prevent the occurrence of fire or its spread, or everything to avert any impending danger.' : 'Klient je povinen neprodleně ohlásit poskytovateli, či správci nebezpečí vzniku požáru, situaci vyžadující zásah policie nebo lékařského ošetření, Klient je povinen učinit vše, aby zabránil vzniku požáru či jeho šíření, nebo vše k odvrácení jakéhokoli hrozícího nebezpečí.'}
<br/> 
15 {language === 'en' ? 'The client is obliged to conserve water and electricity.' : 'Klient je povinen šetřit vodou a elektrickým proudem.'}
<br/> 
16. {language === 'en' ? 'The client is obliged to ensure that the maximum number of people staying in the apartment does not exceed 5 people.' : 'Klient je povinen zajistit, aby maximální počet osob ubytovaných v apartmánu nepřekročil 5 osob.'}
<br/> 
17. {language === 'en' ? 'The client can use free Wi-Fi internet signal coverage. It is forbidden to use the Wi-Fi coverage of the apartment for illegal activities on the Internet (eg downloading movies) or risky activities (eg downloading suspicious files, viewing pornography, sending spam).' : 'Klient může využívat bezplatné Wi-Fi pokrytí internetovým signálem. Je zakázáno využívat Wi-Fi pokrytí apartmánu k nezákonným aktivitám na internetu (tj. např. stahování filmů) nebo k rizikovým aktivitám (např. stahování podezřelých souborů, prohlížení pornografie, rozesílání spamů).'}
<br/> 
18. {language === 'en' ? 'The client is entitled to bed linen upon arrival, the bed linen is changed after the client’s departure.' : 'Klient má při příjezdu nárok na ložní prádlo, výměna ložního prádla se provádí po odjezdu klienta.'}
</p>
<h3>VIII. {language === 'en' ? 'Termination of stay' : 'Ukončení pobytu'}</h3>
<p id='obecne'>
1. {language === 'en' ? 'The end date of the stay is agreed and confirmed in the binding reservation. The client is obliged to leave the apartment on the last day of the stay by 10:00 a.m.' : 'Datum ukončení pobytu je sjednáno a potvrzeno v závazné rezervaci. Klient je povinen opustit apartmán v poslední den pobytu do 10.00 hod.'}
<br/> 
2. {language === 'en' ? 'The provider or administrator is authorized to prematurely terminate the guest’s accommodation in the event that the latter does not comply with these accommodation and operating regulations or other obligations relating to the accommodation, which contain general legal regulations.' : 'Poskytovatel nebo správce jsou oprávnění předčasně ukončit ubytování hosta v případě, že tento nebude dodržovat tento ubytovací a provozní řád či jiné povinnosti vztahující se k ubytování, které obsahuje obecné právní předpisy.'}
<br/> 
3. {language === 'en' ? 'The client is obliged to hand over the apartment in the same condition in which it was received. After the client’s departure, regular cleaning will be provided by the provider. The client is obliged to notify the provider of damage incurred during the stay, or missing equipment, and to pay for the resulting damage according to the invoice submitted by the provider.' : 'Klient je povinen předat apartmán ve stejném stavu, v jakém byl přebrán. Po odjezdu klienta bude zajištěn ze strany poskytovatele běžný úklid. Klient je povinen oznámit poskytovateli škody vzniklé za dobu trvání pobytu, případně chybějící vybavení a uhradit vzniklou škodu dle vyúčtování předloženého poskytovatelem.'}
<br/> 
4. {language === 'en' ? 'The client is obliged to clear out all his belongings, including food. The client is obliged to place waste in the designated waste containers.' : 'Klient je povinen vyklidit všechny své věci včetně potravin. Odpadky je klient povinen umístit do odpadových nádob k tomu určených.'}
<br/> 
5. {language === 'en' ? 'In the event that the client does not hand over the room at the specified time on the day of departure, the provider reserves the right to clear and store the guest’s belongings, through the cleaning lady, so that the room can be available for another reservation.' : 'V případě, že klient v den odjezdu ve stanovenou dobu pokoj nepředá, vyhrazuje si poskytovatel právo věci hosta, prostřednictvím, paní na úklid,vyklidit a uschovat, aby mohl být pokoj k dispozici pro další rezervaci.'}
  </p>
      </main>
      <footer className="footer">
        <Footer/>
      </footer>
    </div>
  );
}
export default Podminky;
