import React from "react";
import Navside from "../App/Navside";
import { useContext } from "react";
import { LanguageContext } from "../App/UserContext";
import Footer from "../components/Footer";
import Header from "../components/Header";
import Krka from "./877F5293-F041-4AB8-82E9-E52CC7D18DA2.jpeg";
import Sibenik from "./4D942477-6404-4789-AEAE-41CCEDD03E1B.jpeg";
import Trogir from"./pexels-hert-niks-12644207.jpg";
import  Primosten from"./pexels-photo-14979797.jpeg";
function Odpocivadlo() {
  const { language, setlanguage } = useContext(LanguageContext);
  return (
    <div className="grid" >
      <header className="header">
      <Header Value={3} />
      </header>
      <main className="main">
          <h1> {language === 'en' ? 'Trips around' : 'Výlety v okolí'}</h1>
          <p className="levo">{language === 'en' ? 'Grebaštica is located on the shores of the Adriatic Sea and offers a variety of options for excursions. Here are some of our tips:' : 'Grebaštica leží na břehu Jaderského moře a nabízí škálu možností, kam se vydat na výlet. Tady je několik našich tipů:'} </p>
          
          <a href="https://www.google.com/maps/place/%C5%A0ibenik,+Chorvatsko/@43.7412135,15.8444154,13z/data=!3m1!4b1!4m6!3m5!1s0x13352615d088d09d:0x400ad50862bcc20!8m2!3d43.7350196!4d15.8952045!16zL20vMDF5OXJr?hl=cs&entry=ttu" target="_blank" rel="noopener noreferrer">
      <h3  className="levo"> Šibenik</h3>  </a><p className="levo"> {language === 'en' ? 'It is only a 15-minute drive from the apartment and you should definitely not miss it. It is an important historical city with many monuments and landmarks, one of which is the famous cathedral of St. Jakub, which is listed on the UNESCO World Cultural Heritage List. Don’t miss the everyday life of Šibenik residents. One option is to go to the market, where you can buy regional specialties, fresh fruit, vegetables, fish and other ingredients for the evening barbecue every day from early in the morning. And if you are interested in haute gastronomy, in Šibenik you will also find the Michelin restaurant Pelegrini.' : 'Je pouhých 15 minut jízdy autem od apartmánu a jeho návštěvu byste rozhodně neměli vynechat. Jde o významné historické město s mnoha památkami a pamětihodnostmi. Za zmínku stojí proslulá katedrála sv. Jakuba, která je zapsána na Seznam světového kulturního dědictví UNESCO. Nenechte si ale ujít ani “běžný” život šibenických obyvatel, zajděte třeba na trh, kde se dají denně od brzkého rána nakoupit krajové speciality, čerstvé ovoce, zelenina, ryby i další suroviny pro večerní grilování. A zajímá-li vás vysoká gastronomie, v Šibeniku najdete i michelinskou restauraci Pelegrini.'}
            </p>
            
           <a href="https://www.google.com/maps/place/N%C3%A1rodn%C3%AD+park+Krka/@43.8666055,15.9699089,17z/data=!3m1!4b1!4m6!3m5!1s0x13352dceeb0b369d:0x12e1d4d5d0103938!8m2!3d43.8666017!4d15.9724838!16zL20vMDF5YmRm?hl=cs&entry=ttu" target="_blank" rel="noopener noreferrer">  <h3  className="levo">{language === 'en' ? 'National park Krka' : 'Národní park Krka'}</h3></a>
         <p className="levo"> {language === 'en' ? 'Breathtaking landscape with waterfalls, rivers and lakes. Beautiful walks and swimming in the wild. We recommend using boat transport from the town of Skradin, which is included in the park entrance fee.' : 'Dechberoucí krajina s vodopády, řekami a jezery. Nádherné procházky a koupání v divoké přírodě. Doporučujeme využít lodní dopravu z městečka Skradin, která je v ceně vstupného do parku.'} </p>
         <a href="https://aquapark-dalmatia.com/" target="_blank" rel="noopener noreferrer"> <h3  className="levo">{language === 'en' ? 'Aquapark Dalmatia' : 'Aquapark Dalmatia'}</h3></a> <p className="levo">{language === 'en' ? 'The brand new (2024) Aquapark Dalmatia in Šibenik is ideal for family fun and relaxation. As part of the Solaris Beach Resort, it offers water slides of various lengths, children\'s pools, and interactive fountains. Visitors can relax in the pools or float along the lazy river in a beautiful, green environment with sun loungers and umbrellas. The area features stalls and restaurants providing refreshments and meals for the whole family. In addition to water attractions, there are other activities and animations that enhance a day full of fun and sunshine.' : 'Zbrusu nový(2024) Aquapark Dalmatia v Šibeniku je ideální pro rodinnou zábavu a relaxaci. Jako součást Solaris Beach Resortu nabízí vodní skluzavky různých délek, dětské bazény a interaktivní fontánky. Návštěvníci mohou relaxovat v bazénech nebo se nechat unášet lenivou řekou v nádherném prostředí plném zeleně s lehátky a slunečníky. V areálu jsou stánky a restaurace s občerstvením a jídly pro celou rodinu. Kromě vodních atrakcí jsou zde další aktivity a animace, které zpestří den plný zábavy a slunce.'}</p>
         <a href="https://www.google.com/maps/place/Primo%C5%A1ten,+Chorvatsko/@43.5834361,15.8964073,14z/data=!3m1!4b1!4m6!3m5!1s0x13352285a126b841:0xa0444300c8544de0!8m2!3d43.586316!4d15.9230156!16zL20vMDkzcXE3?hl=cs&entry=ttu" target="_blank" rel="noopener noreferrer"> <h3  className="levo">Primošten</h3></a> <p className="levo" > {language === 'en' ? 'This seaside town is located on the coast about 10 minutes drive south of the apartment. It is surrounded by beautiful beaches, azure seas and charming bays. In the center is an admirable historical core with narrow cobbled streets and a small harbor with fishing boats.' : 'Toto přímořské městečko se nachází na pobřeží asi 10 minut jízdy na jih od apartmánu. Je obklopeno krásnými plážemi, azurovým mořem a půvabnými zátokami. V centru je hodno obdivu historické jádro s úzkými dlážděnými uličkami a nevelkým přístavem, v němž kotví rybářské lodě.'} </p>  
         <a href="https://www.google.com/maps/place/Trogir,+Chorvatsko/@43.5278499,16.2383224,14z/data=!3m1!4b1!4m6!3m5!1s0x1335420993dd42f7:0x8395754b009b5daa!8m2!3d43.516387!4d16.2501894!16zL20vMDF5OXB4?hl=cs&entry=ttu" target="_blank" rel="noopener noreferrer"> <h3  className="levo">Trogir</h3></a>  <p className="levo">{language === 'en' ? 'Ancient Trogir is another recommended stop, especially for lovers of history and culture. The historic center of the city is made up of a labyrinth of streets.' : 'Starobylý Trogir je další doporučenou zastávkou, především pak pro milovníky dějin a kultury. Historický střed města tvoří labyrint uliček.'} </p> 
    
         <a href="https://www.google.com/maps/place/Krapanj/@43.6732212,15.9040202,16z/data=!3m1!4b1!4m6!3m5!1s0x13352467ac6d76a1:0xa3ae7454de8e52fa!8m2!3d43.6726722!4d15.9119766!16zL20vMGRfemtq?hl=cs&entry=ttu" target="_blank" rel="noopener noreferrer"> <h3>  {language === 'en' ? 'Island Krapanj' : 'Ostrov Krapanj'}</h3></a>  <p className="levo">{language === 'en' ? 'One of our favorite places in the area. This island with undeniable charm can be reached by a "ferry" from the town of Brodarica. Great location for diving and exploring underwater life.' : 'Jedno z našich nejoblíbenějších míst v okolí. Na ostrov s nezaměnitelným kouzlem se dostanete “přívozem” z městečka Brodarica. Skvělá lokalita pro potápění a prozkoumávání podmořského života.'} </p>     
         <a href="https://www.google.com/maps/place/Beach+Bilo/@43.630136,15.9155303,17z/data=!3m1!4b1!4m6!3m5!1s0x133523a67ef447c1:0x1936bf00eb3811e5!8m2!3d43.6301321!4d15.9181052!16s%2Fg%2F11c489r_1g?hl=cs&entry=ttu" target="_blank" rel="noopener noreferrer"> <h3  className="levo">{language === 'en' ? 'Bilo Beach' : 'Pláž Bilo'}</h3></a> <p className="levo">{language === 'en' ? 'It belongs to the most famous Croatian beaches and is just a few minutes down the road from the apartment by car. Perfect for relaxing and swimming.' : 'Patří k nejznámějším chorvatským plážím a najdete ji sotva pár minut jízdy od apartmánu. Dokonalá pro odpočinek a koupání.'}</p>
         <a href="https://www.google.com/maps/place/Ze%C4%8F+O%C5%A1trica/@43.6419779,15.9272833,14z/data=!4m6!3m5!1s0x133523686c79f0df:0xa367d9a661085d0d!8m2!3d43.6419779!4d15.9447928!16s%2Fg%2F11cn3s0k5_?hl=cs&entry=ttu" target="_blank" rel="noopener noreferrer"> <h3  className="levo">{language === 'en' ? 'Oštrica Wall' : 'Zeď Oštrica'}</h3></a> <p className="levo">{language === 'en' ? 'A twenty minute hike or a 5 minute ride can get you to a historic wall. The defensive wall or often called "the Chinese wall" on the peninsula of Oštrica was built in 1497. The wall was built for the purpose of defense in the Croatian–Ottoman wars. Now it also serves as a protection from wildfires making the vast rest of the peninsula a beautiful lush green adventure with hidden beaches and an 500 years isolated and therefore unique ecosystem' : 'Dvacetiminutová procházka nebo 5 minut jízdy autem vás dovede k historické zdi. Obranná zeď často humorně nazývaná „čínská zeď“ na poloostrově Oštrica byla postavena v roce 1497. Zeď byla postavena za účelem obrany v chorvatsko-osmanských válkách. Nyní slouží také jako ochrana před požáry, díky čemuž je rozsáhlý zbytek poloostrova krásně zarostlým, zeleným dobrodružstvím se skrytými plážemi s 500 let izolovaným a tím pádem neobvyklým ekosystémem.'}</p>   
      <div class="grid-onas-fakt">
      <div ><img src={Primosten} width="100%" height="auto"/><p>Primošten</p> </div>
<div ><img src={Krka} width="100%" height="auto"/><p>Krka</p> </div>
<div ><img src={Sibenik} width="100%" height="auto"/><p>Šibenik</p> </div>
<div > <img src={Trogir} width="100%" height="auto"/><p>Trogir</p> </div>
      </div>
      </main>
      <footer className="footer">
        <Footer/>
      </footer>
    </div>
  );
}
export default Odpocivadlo;
