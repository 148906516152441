import React, { useState, useEffect } from "react";
import Navside from "../App/Navside";
import zeptej from "../styles/zeptej.css";
import { useContext } from "react";
import { LanguageContext } from "../App/UserContext";
import Footer from "../components/Footer";
import Header from "../components/Header";
import image1 from "./image1.jpeg";
import image2 from "./image2.jpg";
import image3 from "./image3.jpeg";
import image4 from "./image4.jpeg";
import image5 from "./image5.jpg";
import image6 from "./image6.jpg";
import image7 from "./image7.jpg";
import image8 from "./image8.jpeg";
import image9 from "./image9.jpeg";
import image10 from "./image10.jpeg";
import image11 from "./image11.jpeg";
import image12 from "./image12.jpeg";
import image13 from "./image13.jpeg";
import image14 from "./image14.png";
import image15 from "./image15.jpeg";
import image16 from "./image16.jpeg";
import image17 from "./image17.jpeg";
import image18 from "./image18.jpg";
import image19 from "./image19.jpeg";
import image20 from "./image20.jpeg";
import image21 from "./image21.jpg";
import image22 from "./image22.jpg";
import image23 from "./image23.jpeg";
import image24 from "./image24.jpg";
import image25 from "./image25.jpeg";
import image26 from "./image26.jpg";
import image27 from "./image27.jpg";
import image28 from "./image28.jpg";
import image29 from "./image29.jpg";
import image30 from "./image30.webp";
import image31 from "./image31.jpeg";
import image32 from "./image32.jpg";
function Zeptej() {
  const [selectedImage, setSelectedImage] = useState(null);
  const { language, changelanguage } = useContext(LanguageContext);
  useEffect(() => {
    if (selectedImage !== null) {
      window.addEventListener("keydown", handleKeyDown);
    }

    return () => {
      window.removeEventListener("keydown", handleKeyDown);
    };
  }, [selectedImage]);

  const handleKeyDown = (event) => {
    if (event.key === "ArrowLeft") {
      handlePreviousImage();
    } else if (event.key === "ArrowRight") {
      handleNextImage();
    }
  };

  const handleImageClick = (index) => {
    setSelectedImage(index);
  };

  const handlePreviousImage = () => {
    setSelectedImage((prevImage) => (prevImage === 0 ? 19 : prevImage === 20 ? 31: prevImage - 1));
  };

  const handleNextImage = () => {
    setSelectedImage((prevImage) => (prevImage === 31 ? 20 : prevImage === 19 ? 0: prevImage + 1));
  };

  return (
    <div className="grid">
      <header className="header">
        <Header Value={2} />
      </header>
      <main className="main">
        <div>
        <h1>{language === 'en' ? 'Our apartment' : 'Náš apartmán'}</h1>
        <div className="grid-container">
          {[image1, image2, image3, image4, image5, image6, image7, image8, image9, image10, image11, image12, image13, image14, image15, image16, image17, image18, image19, image20].map((image, index) => (
            <div className="grid-item" key={index} onClick={() => handleImageClick(index)}>
              <img src={image} alt={`Image ${index + 1}`} />
            </div>
          ))}
        </div>

        <hr />
        <hr />

        <h1>Grebaštica</h1>
        <div className="grid-container">
          {[image21,image22,image23,image24,image25,image26,image27,image28,image29,image30,image31,image32].map((image, index) => (
            <div className="grid-item" key={index} onClick={() => handleImageClick(index+20)}>
              <img src={image} alt={`Image ${index + 1}`} />
            </div>
          ))}
        </div>
        {selectedImage !== null && (
          <div className="fullscreen-overlay" onClick={() => setSelectedImage(null)}>
            <div className="fullscreen-image-container">
              <img src={[image1, image2, image3, image4, image5, image6, image7, image8, image9, image10, image11, image12, image13, image14, image15, image16, image17, image18, image19, image20,image21,image22,image23,image24,image25,image26,image27,image28,image29,image30,image31,image32][selectedImage]} alt={`Image ${selectedImage + 1}`} />
              <div className="arrow previous" onClick={handlePreviousImage}></div>
              <div className="arrow next" onClick={handleNextImage}></div>
            </div>
          </div>
        )}
        </div>
      </main>
      <footer className="footer">
        <Footer />
      </footer>
    </div>
  );
}

export default Zeptej;