import React from "react";
import { ReactComponent as Mail} from './envelope-regular.svg';
import { ReactComponent as Telefon} from './phone-solid.svg';
import {Link} from "react-router-dom";
import { useContext } from "react";
import { LanguageContext } from "../App/UserContext";
function Footer() {
  const { language, changelanguage } = useContext(LanguageContext);
  return (
    <footer className="footer">
      <p>{language === 'en' ? 'Contact' : 'Kontakt'}</p>
      <div class='child inline-block-child'><Telefon width="20" height="20" class='child inline-block-child' />
      <h2 style={{ 'padding-left': '5px' , 'padding-right': '20px' }} class='child inline-block-child'><a href="tel:+420731610080">+420&nbsp;731&nbsp;610&nbsp;080</a></h2>
       </div>
       <div class='child inline-block-child'> 
      <Mail width="20" height="20" class='child inline-block-child' />
      <h2 style={{ 'padding-left': '5px' }} class='child inline-block-child'><a href="mailto:dalma.grebastica@gmail.com">dalma.grebastica@gmail.com</a></h2>
      </div>
     
<Link to="/Podminky" onClick={() => window.scrollTo(0, 0)}>{language === 'en' ? 'General terms and conditions' : 'Všeobecné obchodní podmínky'} </Link>
    </footer>
  );
}

export default Footer;